.route-wrap {
  padding: 90px 0 50px;
}

.route-wrap > * + * {
  margin-top: 35px;
}

.route-wrap .routes_box {
  margin-bottom: 0;
}

.route-wrap h1, .route-wrap h2 {
  font: bold 30px/35px "Proxima Nova" !important;
  color: #0b6565 !important;
}

.route-wrap h1, .route-wrap h2 + * {
  margin-top: 30px;
}

.route-wrap .print-visible {
  display: none;
}

.route-wrap .route-section-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.route-wrap .routes_box__body {
  height: 400px;
}

.route-wrap .routes_box__body .blog-article-map {
  height: 100%;
}

.route-wrap .routes_box__legend {
  display: flex;
}

.route-wrap .route-distance {
  padding: 0 15px;
}

.route-wrap .input-wrap {
  display: flex;
  justify-content: space-between;
}

.route-wrap .input-wrap + .input-wrap {
  margin-top: 10px;
}

.route-wrap .booking-item__button.is-right {
  margin-top: 0;
  text-align: right;
}

.route-wrap .routes_box__legend ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  min-height: 17px;
  line-height: 13px;
}

.route-wrap .booking-item__text a {
  position: relative;
}

.route-coordinates {
  list-style-type: decimal;
  list-style-position: inside;
}

.route-coordinates li {
  margin-top: 8px;
  font-size: 15px;
}

.route-coordinates li:first-child {
  color: #0b6565;
  font-weight: bold;
}

.route-coordinates li span {
  display: inline-block;
  min-width: 150px;
}

.route-coordinates li span + span {
  margin-left: 30px;
}

.body-wrap {
  opacity: 1 !important;
}

@media only screen and (max-width: 767px) {
  .route-wrap {
    padding: 0;
  }
  .route-wrap > * + * {
    margin-top: 20px;
  }
  .route-wrap .routes_box__body .blog-article-map {
    width: 100%;
  }
  .route-wrap .routes_box__legend ul {
    margin-left: 15px;
  }
  .route-section-header {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 480px) {
  .routes_box__legend {
    flex-direction: column;
  }
}

@media print {
  .top, .footer, #jivo-iframe-container {
    display: none;
  }
  .route-wrap {
    padding: 0 25px;
    background-color: #fff !important;
  }
  .route-wrap .booking, .route-wrap .print-route-btn {
    display: none;
  }
  .route-wrap .routes_box__body .blog-article-map {
    width: 100%;
  }
}
