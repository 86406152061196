.route {
    &-wrap {
        padding: 90px 0 50px;

        > * + * {
            margin-top: 35px;
            }
        .routes_box {
            margin-bottom: 0;
        }
        h1, h2 {
            font: bold 30px/35px "Proxima Nova" !important;
            color: #0b6565 !important;
        }
        h1, h2 + * {
            margin-top: 30px;
        }
        .print-visible {
            display: none;
        }
        .route-section-header {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 30px;
        }
        .routes_box__body {
            height: 400px;
            .blog-article-map {
                height: 100%;
            }
        }     
        .routes_box__legend {
            display: flex;
        }
        .route-distance {
            padding: 0 15px;
        }
        .input-wrap {
            display: flex;
            justify-content: space-between;
        }
        .input-wrap + .input-wrap {
            margin-top: 10px;
        }

        .booking-item__button.is-right {
            margin-top: 0;
            text-align: right;
        }
        .routes_box__legend ul {
            li {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                min-height: 17px;
                line-height: 13px;
            }
        }
        .booking-item__text a {
            position: relative;
        }

    }    
    &-coordinates {
        list-style-type: decimal;
        list-style-position: inside;

        li {
            margin-top: 8px;
            font-size: 15px;
            &:first-child {
                color: #0b6565;
                font-weight: bold;
            }
            span {
                display: inline-block;
                min-width: 150px;
            }
            span + span {
                margin-left: 30px;
            }
        }
    }
}

.body-wrap {
    opacity: 1 !important;
}

@media only screen and (max-width: 767px) {
    .route-wrap {
        padding: 0;

        > * + *  {
            margin-top: 20px;
        }

        .routes_box__body .blog-article-map {
        width: 100%;
        } 
        .routes_box__legend ul {
            margin-left: 15px;
        }
    }
    .route-section-header {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: 480px) {
    .routes_box__legend {
        flex-direction: column;
    }
}

@media print {
    .top, .footer, #jivo-iframe-container {
        display: none;
    }
    .route-wrap {
        padding: 0 25px;
        background-color: #fff !important;
        
        .booking, .print-route-btn {
            display: none;
        }
    }    
    .route-wrap .routes_box__body .blog-article-map {
        width: 100%;
    }
}




// }